<template>
    <v-dialog persistent max-width="764px" v-model="dialog">
        <v-card>
            <v-card-title v-text="getTitle()"></v-card-title>
            <v-card-text class="pb-0">
                <v-row>
                    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" v-if="errors && errors.message">
                        <v-alert dense outlined type="error" class="mb-0"><span v-text="errors.message"></span></v-alert>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <v-alert dense class="text-subtitle-1" type="warning">
                            <span v-html="getText()"></span>
                        </v-alert>
                        <small class="red--text">Note: System will ignore all inapplicable shifts</small>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="text-right pr-6 pl-6">
                <v-spacer></v-spacer>
                <v-btn text @click="close">Cancel</v-btn>
                <v-btn text color="primary" :disabled="object.shifts.length === 0" :loading="loading" @click="save"><span v-text="getButtonText()"></span></v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>

import { stringifyJSON } from '@/utils/encodes'

export default {
    name: 'ShiftPublishDialog',
    data() {
        return {
            dialog: false,
            loading: false,
            object: {
                shifts: [],
                action: ''
            },
            errors: {}
        }
    },
    methods: {
        open: function() {
            this.dialog = true
        },
        close: function() {
            this.dialog = false
            this.loading = false
            this.object = { shifts: [], action: '' }
            this.errors = {}
        },
        updateObject: function(item) {
            this.object = item
        },
        save: function() {
            this.loading = true
            const object = { shifts: stringifyJSON(this.object.shifts), action: this.object.action }
            this.$store.dispatch('epanel/shift/publishShifts', { object: object }).then((response) => {
                this.close()
                this.$emit('updated', { object: response.data.object, message: response.message })
            }).catch((errors) => {
                this.errors = errors.data?errors.data.errors:errors
                this.loading = false
            })
        },
        getTitle: function() {
            return this.object.action === 1?'Publish Shift':'Unpublish Shift'
        },
        getText: function() {
            if(this.object.shifts.length === 0){
                return 'No applicable shift is selected'
            }

            if(this.object.action === 1){
                return this.object.shifts.length > 1?'Are you sure that you want to publish those shifts?':'Are you sure that you want to publish this shift?'
            }else{
                return this.object.shifts.length > 1?'Are you sure that you want to unpublish those shifts?':'Are you sure that you want to unpublish this shift?'
            }
        },
        getButtonText: function() {
            return this.object.action === 1?'Publish':'Unpublish'
        }
    }
}

</script>