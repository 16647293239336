<template>
	<v-dialog persistent max-width="764px" ref="searchDialog" v-model="dialog">
		<v-card>
            <v-card-title>
                <span class="text-h5">Search Promoter</span>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <v-text-field clearable label="Promoter Name" v-model="filters.name"></v-text-field>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <v-select clearable label="Area" :items="areas" :menu-props="{top: false, offsetY: true}" v-model="filters.area"></v-select>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="pl-4 pr-4">
                <v-spacer></v-spacer>
                <v-btn text @click="close">Close</v-btn>
                <v-btn text color="primary" @click="save">Search</v-btn>
            </v-card-actions>
        </v-card>
	</v-dialog>
</template>


<script>

export default {
	name: 'PromoterSearchDialog',
	props: {
        areas: {
            type: Array,
            required: false,
            default: () => []
        }
	},
	data() {
		return {
			dialog: false,
			filters: {
                name: '',
                area: ''
			}
		}
	},
	computed: {
		searchDialog() {
			return this.$refs.createDialog
		}
	},
	methods: {
		open: function() {
			this.dialog = true
		},
		close: function() {
			this.dialog = false
            this.filters = { name: '', area: '' }
		},
		save: function() {
            this.$emit("updated", this.filters)
            this.dialog = false
		}
	}
}

</script>