<template>
	<v-dialog persistent max-width="764px" ref="createDialog" v-model="dialog">
		<v-form lazy-validation ref="form" v-model="valid">
			<v-card>
				<v-card-title>
					<span class="text-h5">Update Shift</span>
				</v-card-title>
				<v-card-text>
					<v-row>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" v-if="errors && errors.message">
                            <v-alert dense outlined type="error" class="mb-0"><span v-text="errors.message"></span></v-alert>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
							<v-autocomplete label="Outlet*" :items="outlets" :rules="rules.outlet" :error-messages="errors.outlet" v-model="object.outlet"></v-autocomplete>
						</v-col>
						<v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <v-dialog persistent width="320px" :return-value.sync="object.start_time" ref="startTimeDialog" v-model="startTimeDialog">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field readonly label="Start Time*" :rules="rules.start_time" :error-messages="errors.start_time" v-bind="attrs" v-on="on" v-model="object.start_time"></v-text-field>
                                </template>
                                <v-time-picker color="primary" format="24hr" :allowed-minutes="allowedMinutes" v-model="object.start_time">
                                    <v-spacer></v-spacer>
                                    <v-btn text color="primary" @click="startTimeDialog = false">Cancel</v-btn>
                                    <v-btn text color="primary" @click="saveStartTime">OK</v-btn>
                                </v-time-picker>
                            </v-dialog>
						</v-col>
						<v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <v-dialog persistent width="320px" :return-value.sync="object.end_time" ref="endTimeDialog" v-model="endTimeDialog">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field readonly label="End Time*" :rules="rules.end_time" :error-messages="errors.end_time" v-bind="attrs" v-on="on" v-model="object.end_time"></v-text-field>
                                </template>
                                <v-time-picker color="primary" format="24hr" :allowed-hours="allowedHours" :allowed-minutes="allowedMinutes" v-model="object.end_time">
                                    <v-spacer></v-spacer>
                                    <v-btn text color="primary" @click="endTimeDialog = false">Cancel</v-btn>
                                    <v-btn text color="primary" @click="$refs.endTimeDialog.save(object.end_time)">OK</v-btn>
                                </v-time-picker>
                            </v-dialog>
						</v-col>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <v-text-field label="Allowed Break Hour*" :rules="rules.break_time" :error-messages="errors.break_time" v-model="object.break_time"></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <v-text-field label="Hourly Rate*" :rules="rules.hourly_rate" :error-messages="errors.hourly_rate" v-model="object.hourly_rate"></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <v-text-field label="Commission*" :rules="rules.commission" :error-messages="errors.commission" v-model="object.commission"></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <v-text-field label="Project Fee*" :rules="rules.project_fee" :error-messages="errors.project_fee" v-model="object.project_fee"></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <v-text-field label="PH Pay*" :rules="rules.ph_pay" :error-messages="errors.ph_pay" v-model="object.ph_pay"></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <v-text-field label="Transport Rate*" :rules="rules.transport_rate" :error-messages="errors.transport_rate" v-model="object.transport_rate"></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <v-text-field label="Cooking Rate*" :rules="rules.cooking_rate" :error-messages="errors.cooking_rate" v-model="object.cooking_rate"></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <v-text-field label="Training Rate*" :rules="rules.training_rate" :error-messages="errors.training_rate" v-model="object.training_rate"></v-text-field>
                        </v-col>
						<v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <v-autocomplete clearable label="Promoter" :items="promoters" :error-messages="errors.promoter" v-model="object.promoter"></v-autocomplete>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <v-select label="Promoter Status" :items="statuses" :rules="rules.promoter_status" :error-messages="errors.promoter_status" v-model="object.promoter_status"></v-select>
                        </v-col>
					</v-row>
					<v-row>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <small class="red--text">* Required field</small>
                        </v-col>
                    </v-row>
				</v-card-text>
				<v-card-actions class="pl-4 pr-4">
					<v-spacer></v-spacer>
					<v-btn text :ripple="false" :disabled="loading" @click="close">Close</v-btn>
					<v-btn text color="primary" :ripple="false" :loading="loading" @click="save">Save Changes</v-btn>
				</v-card-actions>
			</v-card>
		</v-form>
	</v-dialog>
</template>


<script>

import { copyObject } from '@/utils/helper'
import { addHours } from '@/utils/dates'

export default {
	name: 'ShiftUpdateDialog',
	props: {
        outlets: {
            type: Array,
            required: false,
            default: () => []
        },
        promoters: {
            type: Array,
            required: false,
            default: () => []
        },
        startDate: {
            type: String,
            required: false,
            default: null
        },
        endDate: {
            type: String,
            required: false,
            default: null
        },
        statuses: {
            type: Array,
            required: true,
            default: () => []
        },
	},
	data() {
		return {
			dialog: false,
			valid: true,
			loading: false,
			startTimeDialog: false,
			endTimeDialog: false,
			object: {
                campaign: this.$route.params.id,
                outlet: '',
                promoter: '',
                start_time: '',
                end_time:'',
                break_time: '',
                hourly_rate: '',
                commisson: '',
                project_fee: '',
                ph_pay: '',
                transport_rate: '',
                cooking_rate: '',
                training_rate: '',
                promoter_status: '',
			},
			rules: {
                outlet: [
                    (value) => !!value || 'Outlet is required'
                ],
                start_time: [
                    (value) => !!value || 'Start time is required',
                ],
                end_time: [
                    (value) => !!value || 'End time is required',
                ],
                break_time: [
                    (value) => {
                        if(!value) {
                            return 'Allowed break hour is required'
                        }

                        if(isNaN(value)) {
                            return "Allowed break hour must be numeric value"
                        }

                        return true
                    }
                ],
                hourly_rate: [
                    (value) => {
                        if(!value) {
                            return 'Hourly rate is required'
                        }

                        if(isNaN(value)) {
                            return "Hourly rate must be numeric value"
                        }

                        return true
                    }
                ],
                // commission: [
                //     (value) => {
                //         if(!value) {
                //             return 'Commission is required'
                //         }

                //         if(isNaN(value)) {
                //             return "Commission must be numeric value"
                //         }

                //         return true
                //     }
                // ],
                // project_fee: [
                //     (value) => {
                //         if(!value) {
                //             return 'Project fee is required'
                //         }

                //         if(isNaN(value)) {
                //             return "Project fee must be numeric value"
                //         }

                //         return true
                //     }
                // ],
                // ph_pay: [
                //     (value) => {
                //         if(!value) {
                //             return 'PH pay is required'
                //         }

                //         if(isNaN(value)) {
                //             return "PH pay must be numeric value"
                //         }

                //         return true
                //     }
                // ],
                // transport_rate: [
                //     (value) => {
                //         if(!value) {
                //             return 'Transport rate is required'
                //         }

                //         if(isNaN(value)) {
                //             return "Transport rate must be numeric value"
                //         }

                //         return true
                //     }
                // ],
                // cooking_rate: [
                //     (value) => {
                //         if(!value) {
                //             return 'Cooking rate is required'
                //         }

                //         if(isNaN(value)) {
                //             return "Cooking rate must be numeric value"
                //         }

                //         return true
                //     }
                // ],
                // training_rate: [
                //     (value) => {
                //         if(!value) {
                //             return 'Training rate is required'
                //         }

                //         if(isNaN(value)) {
                //             return "Training rate must be numeric value"
                //         }

                //         return true
                //     }
                // ],
			},
			errors: {}
		}
	},
	computed: {
		createDialog() {
			return this.$refs.createDialog
		},
		form() {
			return this.$refs.form
		}
	},
	methods: {
		open: function() {
			this.dialog = true
		},
		close: function() {
			this.dialog = false
			this.loading = false
            this.object = { campaign: this.$route.params.id, outlet: '', start_time: '', end_time: '', promoter: '',
                            break_time: '1', hourly_rate: '10', commission: '0', project_fee: '0', ph_pay: '0', transport_rate: '0', cooking_rate: '0', training_rate: '0'}
			this.errors = {}
			this.form.reset()
			this.form.resetValidation()
		},
        updateObject: function(item){
            this.object = item
        },
		save: function() {
			if(this.form.validate()){
				this.loading = true
                var object = copyObject(this.object, ['id'])
                this.$store.dispatch('epanel/shift/updateShift', { id: this.object.id, object: object }).then((response) => {
                    this.$emit('updated', {object: response.data.object, message: response.message})
                    this.close()
                }).catch((errors) => {
                    this.errors = errors.data?errors.data.errors:errors
                    this.loading = false
                })
			}
		},
        saveStartTime: function() {
            this.$refs.startTimeDialog.save(this.object.start_time)
            this.object.end_time = addHours(this.object.start_time, 8)
            const startTimeHour = parseInt(this.object.start_time.substring(0, 2))
            const endTimeHour = parseInt(this.object.end_time.substring(0, 2))

            if(startTimeHour > endTimeHour){
                this.object.end_time = "00:00"
            }

            this.$refs.endTimeDialog.save(this.object.end_time)
        },
        allowedHours: function(v) {
            if(this.object.start_time){
                const hour = parseInt(this.object.start_time.substring(0, 2))
                return v >= hour
            }
            return v
        },
        allowedMinutes: function(v) {
            return v % 5 === 0
        }
	}
}

</script>