<template>
    <div>
        <v-row v-if="!loading && !permissions.can_deploy">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <p class="text-h5 mb-4">Campaign Shift Deployment</p>
                <p class="text-title mb-0">You do not have permission to view campaign shift deployment</p>
            </v-col>
        </v-row>
        <template v-if="permissions.can_deploy">
            <v-row class="pb-6">
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-sheet color="white" elevation="0" class="pa-0 px-1">
                        <p class="text-h6 mb-4">Campaign Info</p>
                        <v-row>
                            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                <display-label label="Brand Name" :text="object.project"></display-label>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                <display-label label="Campaign Name" :text="object.name"></display-label>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                <display-label label="Start Date" :text="object.start_date.text"></display-label>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                <display-label label="End Date" :text="object.end_date.text"></display-label>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <display-label list label="Project Managers" v-if="object.managers.length > 0">
                                    <div slot="list">
                                        <v-chip small label color="primary" class="mr-2" :key="index" v-for="(item, index) in object.managers"><span v-text="item.text"></span></v-chip>
                                    </div>
                                </display-label>
                                <display-label label="Project Managers" text="N.A." v-if="object.managers.length == 0"></display-label>
                            </v-col>
                        </v-row>
                    </v-sheet>
                </v-col>
            </v-row>
            <shift-list></shift-list>
        </template>

    </div>
</template>


<script>

import DisplayLabel from '../../../shared/DisplayLabel.vue'
import ShiftList from './ShiftList.vue'

export default {
    name: 'DeploymentList',
    components: {
        displayLabel: DisplayLabel,
        shiftList: ShiftList,
    },
    data() {
        return {
            loading: true,
            object: {
                project: '',
                name: '',
                min_date: "",
                start_date: {},
                end_date: {},
                managers: []
            },
            permissions: {
                can_view: false,
            }
        }
    },
    mounted: function() {
        this.get()
    },
    methods: {
        get: function() {
            this.loading = true
            this.$store.dispatch('epanel/project/getProjectCampaign', { id: this.$route.params.id }).then((response) => {
                this.object = response.data.object
                this.permissions = response.data.permissions
                this.loading = false
            }).catch(() => {
                this.loading = false
            })
        }
    }
}

</script>


<style scoped>
/deep/ .v-toolbar.v-toolbar--dense, /deep/ .v-toolbar__content {
    height: 36px !important;
}


</style>