<template>
    <v-dialog persistent max-width="764px" v-model="dialog">
        <v-card>
            <v-card-title v-text="getTitle()"></v-card-title>
            <v-card-text class="pb-0">
                <v-row>
                    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" v-if="errors && errors.message">
                        <v-alert dense outlined type="error" class="mb-0"><span v-text="errors.message"></span></v-alert>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <v-alert dense class="text-subtitle-1" type="warning">
                            <span v-html="getText()"></span>
                        </v-alert>
                        <small class="red--text">Note: System will ignore all inapplicable shifts</small>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="text-right pr-6 pl-6">
                <v-spacer></v-spacer>
                <v-btn text @click="close">Cancel</v-btn>
                <v-btn text :color="getButtonColor()" :disabled="object.shifts.length === 0" :loading="loading" @click="save"><span v-text="getButtonText()"></span></v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>

import { stringifyJSON } from '@/utils/encodes'

export default {
    name: 'ShiftDeployDialog',
    props: {
        isDeploy: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            dialog: false,
            loading: false,
            object: {
                shifts: [],
                account: '',
            },
            errors: {}
        }
    },
    methods: {
        open: function() {
            this.dialog = true
        },
        close: function() {
            this.dialog = false
            this.loading = false
            this.object = { shifts: [], account: '' }
            this.errors = {}
        },
        updateObject: function(item) {
            this.object = item
        },
        save: function() {
            this.loading = true
            const object = { shifts: stringifyJSON(this.object.shifts), account: this.object.account }
            this.$store.dispatch('epanel/shift/deployShifts', { object: object }).then((response) => {
                this.close()
                this.$emit('deployed', { message: response.message })
            }).catch((errors) => {
                this.message = errors.data?errors.data.errors:errors
                this.loading = false
            })
        },
        getTitle: function() {
            return this.isDeploy?'Deploy Promoter':'Remove Promoter'
        },
        getText: function() {
            if(this.object.shifts.length === 0){
                return 'No applicable shift is found'
            }

            if(this.isDeploy){
                return this.object.shifts.length > 1?'Are you sure that you want to deploy those shifts on this promoter?':'Are you sure that you want to deploy this shift on this promoter?'
            }else{
                return this.object.shifts.length > 1?'Are you sure that you want to remove those shifts\' promoter?':'Are you sure that you want to remove this shift\'s promoter?'
            }
        },
        getButtonColor: function() {
            return this.isDeploy?'primary':'red'
        },
        getButtonText: function() {
            return this.isDeploy?'Deploy':'Remove'
        }
    }
}

</script>