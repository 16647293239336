<template>
	<v-dialog persistent max-width="764px" ref="createDialog" v-model="dialog">
		<v-form lazy-validation ref="form" v-model="valid">
			<v-card>
				<v-card-title>
					<span class="text-h5">Create Shift</span>
				</v-card-title>
				<v-card-text>
					<v-row>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" v-if="errors && errors.message">
                            <v-alert dense outlined type="error" class="mb-0"><span v-text="errors.message"></span></v-alert>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
							<v-autocomplete small-chips multiple label="Outlet*" :items="outlets" :rules="rules.outlets" :error-messages="errors.outlets" v-model="object.outlets"></v-autocomplete>
						</v-col>
						<v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
							<v-dialog persistent width="320px" ref="workDateDialog" :return-value.sync="object.work_dates" v-model="workDateDialog">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field readonly dense label="Work Date*" :rules="rules.work_dates" :error-messages="errors.work_dates" v-on="on" v-bind="attrs" v-model="object.work_dates"></v-text-field>
                                </template>
                                <v-date-picker scrollable multiple show-current color="primary" :events="shiftDates" :min="minDate" :max="maxDate" v-model="object.work_dates">
                                    <v-spacer></v-spacer>
                                    <v-btn text color="primary" @click="workDateDialog=false">Cancel</v-btn>
                                    <v-btn text color="primary" @click="$refs.workDateDialog.save(object.work_dates)">OK</v-btn>
                                </v-date-picker>
                            </v-dialog>
						</v-col>

                        <v-col v-for="(date, index) in selectedDates" :key="index" cols="12">
                            <v-list>
                                <v-list-item>
                                    <div>{{ date }}</div>
                                    <v-col class="pt-0 pb-0">
                                        <v-dialog persistent width="300px" :return-value.sync="object.selectedWorkDates[index].start_time" :ref="'startTimeDialog'" v-model="startTimeDialog[index]">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field readonly label="Start Time*" :rules="rules.start_time" :error-messages="errors.start_time" v-bind="attrs" v-on="on" v-model="object.selectedWorkDates[index].start_time"></v-text-field>
                                            </template>
                                            <v-time-picker color="primary" :allowed-minutes="allowedMinutes" format="24hr" v-model="object.selectedWorkDates[index].start_time" v-if="startTimeDialog[index]">
                                                <v-spacer></v-spacer>
                                                <v-btn text color="primary" @click="closeStartTimeDialog(index)">Cancel</v-btn>
                                                <v-btn text color="primary" @click="saveStartTime(index)">OK</v-btn>
                                            </v-time-picker>
                                        </v-dialog>
                                    </v-col>
                                    <v-col class="pt-0 pb-0">
                                        <v-dialog persistent width="300px" :return-value.sync="object.selectedWorkDates[index].end_time" :ref="'endTimeDialog'" v-model="endTimeDialog[index]">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field readonly label="End Time*" :rules="rules.end_time" :error-messages="errors.end_time" v-bind="attrs" v-on="on" v-model="object.selectedWorkDates[index].end_time"></v-text-field>
                                            </template>
                                            <v-time-picker color="primary" format="24hr" :allowed-hours="allowedHours" :allowed-minutes="allowedMinutes" v-model="object.selectedWorkDates[index].end_time" v-if="endTimeDialog[index]">
                                                <v-spacer></v-spacer>
                                                <v-btn text color="primary" @click="closeEndTimeDialog(index)">Cancel</v-btn>
                                                <v-btn text color="primary" @click="saveEndTime(index)">OK</v-btn>
                                            </v-time-picker>
                                        </v-dialog>
                                    </v-col>
                                </v-list-item>
                            </v-list>
                        </v-col>

						<v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <v-text-field label="Allowed Break Hour*" :rules="rules.break_time" :error-messages="errors.break_time" v-model="object.break_time"></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <v-text-field label="Hourly Rate*" :rules="rules.hourly_rate" :error-messages="errors.hourly_rate" v-model="object.hourly_rate"></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
                            <v-text-field label="Commission (sum)*" :rules="rules.commission" :error-messages="errors.commission" v-model="object.commission"></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
                            <v-text-field label="Project Fee (sum)*" :rules="rules.project_fee" :error-messages="errors.project_fee" v-model="object.project_fee"></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
                            <v-text-field label="PH Pay (per hour)*" :rules="rules.ph_pay" :error-messages="errors.ph_pay" v-model="object.ph_pay"></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <v-checkbox dense hide-details label="Reimburstment" v-model="object.is_reimburstment"></v-checkbox>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4" v-if="object.is_reimburstment">
                            <v-text-field label="Transport rate" :rules="rules.transport_rate" :error-messages="errors.transport_rate" v-model="object.transport_rate"></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4" v-if="object.is_reimburstment">
                            <v-text-field readonly label="Cooking rate" :rules="rules.cooking_rate" :error-messages="errors.cooking_rate" v-model="object.cooking_rate"></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4" v-if="object.is_reimburstment">
                            <v-text-field label="Training rate" :rules="rules.training_rate" :error-messages="errors.training_rate" v-model="object.training_rate"></v-text-field>
                        </v-col>
						<v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <v-checkbox dense hide-details label="Assign Promoter?" @change="handleAssignPromoter" v-model="object.is_promoter"></v-checkbox>
                        </v-col>
						<v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" v-if="!object.is_promoter">
                            <v-text-field label="No. of Shifts*" :rules="rules.quantity" :error-messages="errors.quantity" v-model="object.quantity"></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" v-if="object.is_promoter">
                            <v-autocomplete small-chips multiple clearable label="Promoter*" placeholder="Searching for promoters" :items="promoters" :rules="rules.promoters" :error-messages="errors.promoters" v-model="object.promoters"></v-autocomplete>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" v-if="object.is_promoter">
                            <v-select label="Promoter Status" :items="statuses" :rules="rules.promoter_status" :error-messages="errors.promoter_status" v-model="object.promoter_status"></v-select>
                        </v-col>
					</v-row>
					<v-row>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <small class="red--text">* Required field</small><br>
                            <small class="red--text">Note: Outlet can choose one if the assign promoter is checked</small>
                        </v-col>
                    </v-row>
				</v-card-text>
				<v-card-actions class="pl-4 pr-4">
					<v-spacer></v-spacer>
					<v-btn text :ripple="false" :disabled="loading" @click="close">Close</v-btn>
					<v-btn text color="primary" :ripple="false" :loading="loading" @click="create">Create</v-btn>
				</v-card-actions>
			</v-card>
		</v-form>
	</v-dialog>
</template>


<script>

import { copyObject } from '@/utils/helper'
import { stringifyJSON } from '@/utils/encodes'
import { addHours } from '@/utils/dates'

export default {
	name: 'ShiftCreateDialog',
	props: {
        outlets: {
            type: Array,
            required: false,
            default: () => []
        },
        shiftDates: {
            type: Array,
            required: false,
            default: () => []
        },
        promoters: {
            type: Array,
            required: false,
            default: () => []
        },
        minDate: {
            type: String,
            required: false,
            default: null
        },
        maxDate: {
            type: String,
            required: false,
            default: null
        },
        statuses: {
            type: Array,
            required: true,
            default: () => []
        },
	},
	data() {
		return {
            time: null,
            menu2: false,
            modal2: false,
			dialog: false,
			valid: true,
			loading: false,
			workDateDialog: false,
			startTimeDialog: [],
			endTimeDialog: [],
			object: {
                campaign: this.$route.params.id,
                outlets: [],
                promoters: [],
                work_dates: [],
                start_time: '',
                end_time:'',
                selectedWorkDates: [],
                quantity: '',
                break_time: '1',
                hourly_rate: '10',
                is_promoter: false,
                promoter_status: '',
                commission: '0',
                project_fee: '0',
                ph_pay: '0',
                is_reimburstment: false,
                transport_rate: '0',
                cooking_rate: '0',
                training_rate: '0',
			},
			rules: {
                outlets: [
                    (value) => {
                        if(value.length === 0) {
                            return 'Outlet is required'
                        }

                        if(this.object.is_promoter && value.length > 1){
                            return 'Outlet only can choose one if assign promoter is checked'
                        }

                        return true
                    }
                ],
                work_dates: [
                    (value) => {
                        if(value.length === 0) {
                            return 'Work date is required'
                        }

                        return true
                    }
                ],
                start_time: [
                    (value) => !!value || 'Start time is required',
                ],
                end_time: [
                    (value) => !!value || 'End time is required',
                ],
                quantity: [
                    (value) => {
                        if(!value) {
                            return 'Quantity is required'
                        }

                        if(!/^\d+$/.test(value)) {
                            return "Quantity must be integer value"
                        }

                        return true
                    }
                ],
                promoters: [
                    (value) => {
                        if(this.object.is_promoter && value.length === 0) {
                            return 'Promoter is required'
                        }

                        return true
                    }
                ],
                promoter_status: [
                    (value) => {
                        if(this.object.is_promoter && value.length === 0) {
                            return 'Promoter status is required'
                        }

                        return true
                    }
                ],
                break_time: [
                    (value) => {
                        if(!value) {
                            return 'Allowed break hour is required'
                        }

                        if(isNaN(value)) {
                            return "Allowed break hour must be numeric value"
                        }

                        return true
                    }
                ],
                hourly_rate: [
                    (value) => {
                        if(!value) {
                            return 'Hourly rate is required'
                        }

                        if(isNaN(value)) {
                            return "Hourly rate must be numeric value"
                        }

                        return true
                    }
                ],
                // commission: [
                //     (value) => {
                //         if(!value) {
                //             return 'Commission is required'
                //         }

                //         if(isNaN(value)) {
                //             return "Commission rate must be numeric value"
                //         }

                //         return true
                //     }
                // ],
                // project_fee: [
                //     (value) => {
                //         if(!value) {
                //             return 'Project fee is required'
                //         }

                //         if(isNaN(value)) {
                //             return "Project fee must be numeric value"
                //         }

                //         return true
                //     }
                // ],
                // ph_pay: [
                //     (value) => {
                //         if(!value) {
                //             return 'PH pay is required'
                //         }

                //         if(isNaN(value)) {
                //             return "PH pay must be numeric value"
                //         }

                //         return true
                //     }
                // ],
                // training_rate: [
                //     (value) => {
                //         if(!value) {
                //             return 'Training rate is required'
                //         }

                //         if(isNaN(value)) {
                //             return "Training rate must be numeric value"
                //         }

                //         return true
                //     }
                // ],
                // transport_rate: [
                //     (value) => {
                //         if(!value) {
                //             return 'Transport rate is required'
                //         }

                //         if(isNaN(value)) {
                //             return "Transport rate must be numeric value"
                //         }

                //         return true
                //     }
                // ],
                // cooking_rate: [
                //     (value) => {
                //         if(!value) {
                //             return 'Cooking rate is required'
                //         }

                //         if(isNaN(value)) {
                //             return "Cooking rate must be numeric value"
                //         }

                //         return true
                //     }
                // ],
			},
			errors: {}
		}
	},
    created() {
        this.startTimeDialog = new Array(this.selectedDates.length).fill(false);
        this.endTimeDialog = new Array(this.selectedDates.length).fill(false);
    },
	computed: {
		createDialog() {
			return this.$refs.createDialog
		},
		form() {
			return this.$refs.form
		},
        selectedDates() {
            return this.object.selectedWorkDates.map((workDate) => {
                return new Date(workDate.date).toLocaleDateString()
            });
        },
	},
    watch: {
        'object.work_dates': {
            immediate: true,
            handler() {
                this.updateSelectedWorkDates();
            },
        },
    },
	methods: {
		open: function() {
			this.dialog = true
		},
		close: function() {
			this.dialog = false
			this.loading = false
			this.form.resetValidation()
            this.object = { campaign: this.$route.params.id, outlets: [], work_dates: [], selectedWorkDates: [],
                            quantity: '', break_time: '1', hourly_rate: '10', promoter_status: '', commission: '0', project_fee: '0', ph_pay: '0',  transport_rate: '0', cooking_rate: '0', training_rate: '0' }
			this.errors = {}
		},
		create: function() {
			if(this.form.validate()){
				this.loading = true
                const object = copyObject(this.object, ['outlets', 'selectedWorkDates', 'promoters', 'promoter_status'])
                object.outlets = stringifyJSON(this.object.outlets)
                object.selectedWorkDates = stringifyJSON(this.object.selectedWorkDates)
                object.promoters = this.object.is_promoter?stringifyJSON(this.object.promoters):''
                object.promoter_status = this.object.promoter_status

				this.$store.dispatch('epanel/shift/createShift', object).then((response) => {
                    this.close()
                    this.$emit('created', { items: response.data.shifts, message: response.message })
                }).catch((errors) => {
                    this.errors = errors.data?errors.data.errors:errors
                    this.loading = false
                })
			}
		},
        // saveStartTime: function() {
        //     this.$refs.startTimeDialog.save(this.object.start_time)
        //     this.object.end_time = addHours(this.object.start_time, 8)
        //     const startTimeHour = parseInt(this.object.start_time.substring(0, 2))
        //     const endTimeHour = parseInt(this.object.end_time.substring(0, 2))

        //     if(startTimeHour > endTimeHour){
        //         this.object.end_time = "00:00"
        //     }

        //     this.$refs.endTimeDialog.save(this.object.end_time)
        // },
        saveStartTime: function(index) {
            this.$refs['startTimeDialog'][index].save(this.object.selectedWorkDates[index].start_time);
            this.object.selectedWorkDates[index].end_time = addHours(this.object.selectedWorkDates[index].start_time, 8);
            const startTimeHour = parseInt(this.object.selectedWorkDates[index].start_time.substring(0, 2));
            const endTimeHour = parseInt(this.object.selectedWorkDates[index].end_time.substring(0, 2));

            if (startTimeHour > endTimeHour) {
                this.object.selectedWorkDates[index].start_time = "00:00";
            }

            this.object.selectedWorkDates[index].start_time = '';
            this.startTimeDialog[index] = false;
        },
        saveEndTime: function(index) {
            this.$refs['endTimeDialog'][index].save(this.object.selectedWorkDates[index].end_time);

            const startTimeHour = parseInt(this.object.selectedWorkDates[index].start_time.substring(0, 2));
            const endTimeHour = parseInt(this.object.selectedWorkDates[index].end_time.substring(0, 2));

            if (startTimeHour > endTimeHour) {
                this.object.selectedWorkDates[index].start_time = "00:00";
            }

            this.object.selectedWorkDates[index].end_time = '';
            this.endTimeDialog[index] = false;
        },
        handleAssignPromoter: function(isChecked) {
            if(isChecked){
                this.object.promoters = []
            }else {
                this.object.quantity = ''
            }
        },
        allowedHours: function(v) {
            if(this.object.start_time){
                const hour = parseInt(this.object.start_time.substring(0, 2))
                return v >= hour
            }
            return v
        },
        allowedMinutes: function(v) {
            return v % 5 === 0
        },
        updateSelectedWorkDates() {
            this.object.selectedWorkDates = this.object.work_dates.map((date) => ({
                date,
                start_time: '',
                end_time: '',
            }));
        },
        closeStartTimeDialog(index) {
            this.startTimeDialog = [...this.startTimeDialog];
            this.$set(this.startTimeDialog, index, false);
        },
        closeEndTimeDialog(index) {
            this.endTimeDialog = [...this.endTimeDialog];
            this.$set(this.endTimeDialog, index, false);
        }
	}
}

</script>