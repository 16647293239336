<template>
	<v-dialog persistent max-width="764px" ref="createDialog" v-model="dialog">
		<v-card>
            <v-card-title>
                <span class="text-h5 mb-3">Conflicted Shift List</span>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <v-simple-table dense>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <td class="text-center">Promoter Name</td>
                                        <td class="text-center">Mobile No.</td>
                                        <td class="text-center">Work Date</td>
                                        <td class="text-center">Start Time</td>
                                        <td class="text-center">End Time</td>
                                        <td class="text-center">Project Working On</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-for="(item, index) in items">
                                        <tr :key="getKey(index, sindex)" v-for="(sitem, sindex) in item.shifts">
                                            <td class="text-center" v-text="item.promoter.name"></td>
                                            <td class="text-center" v-text="item.promoter.mobile_number"></td>
                                            <td class="text-center" v-text="sitem.work_date"></td>
                                            <td class="text-center" v-text="sitem.start_time"></td>
                                            <td class="text-center" v-text="sitem.end_time"></td>
                                            <td class="text-center" v-text="sitem.campaign"></td>
                                        </tr>
                                    </template>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="pl-4 pr-4">
                <v-spacer></v-spacer>
                <v-btn text @click="close">Close</v-btn>
            </v-card-actions>
        </v-card>
	</v-dialog>
</template>


<script>

export default {
	name: 'ShiftConflictDialog',
	data() {
		return {
            dialog: false,
            items: [],
		}
	},
	methods: {
		open: function() {
			this.dialog = true
		},
		close: function() {
			this.dialog = false
			this.loading = false
			this.items = []
		},
        updateItems: function(items) {
            this.items = items
        },
        getKey: function(index, sindex){
            return index.toString() + sindex.toString()
        }
	}
}

</script>