<template>
    <div>
        <v-navigation-drawer app right width="200" v-model="drawer">
            <v-toolbar color="primary" class="white--text elevation-0 pt-2 pb-0" height="48">Search Promoters</v-toolbar>
            <v-toolbar class="white--text elevation-0 d-flex align-center" height="60" v-if="permissions.can_deploy">
                <v-text-field hide-details clearable label="Search..." append-icon="mdi-magnify" append-outer-icon="mdi-cog-outline" @click:clear="reset" @click:append="search" @click:append-outer="openSearchDialog" v-model="filters.name"></v-text-field>
            </v-toolbar>
            <v-divider></v-divider>
            <v-list dense two-line class="list">
                <div :key="index" v-for="(item, index) in items">
                    <v-menu absolute offset-y class="menu">
                        <template v-slot:activator="{ on, attrs }">
                            <v-list-item v-bind="attrs" v-on="on" class="list-item">
                                <v-list-item-content>
                                    <v-list-item-title :class="getStatusColor(item.is_free)" v-text="item.name"></v-list-item-title>
                                    <v-list-item-subtitle :class="getStatusColor(item.is_free)" v-text="item.mobile_number"></v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action class="my-2">
                                    <v-chip small label color="success" class="mb-1" v-bind="attrs" v-on="on" ><span v-text="item.times"></span></v-chip>
                                    <v-chip small label color="info" v-bind="attrs" v-on="on" ><span v-text="item.area"></span></v-chip>
                                </v-list-item-action>
                            </v-list-item>
                        </template>
                        <v-list dense class="action-list">
                            <v-list-item @click="openInfoDialog(item, index)">
                                <v-list-item-title>View Profile</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="openDeployDialog(item)" v-if="isDeployable(item)">
                                <v-list-item-title>Deploy</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <v-divider></v-divider>
                </div>
                <v-list-item v-if="!loading && items.length === 0">
                    <v-list-item-content class="text-center">
                        <v-list-item-title>No Promoter Found</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="loading && items.length === 0">
                    <v-list-item-content class="text-center">
                        <v-list-item-title>Loading data</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
        <info-dialog ref="infoDialog"></info-dialog>
        <search-dialog ref="searchDialog" :areas="areas" @updated="updateFilters"></search-dialog>
    </div>
</template>


<script>

import PromoterInfoDialog from './PromoterInfoDialog.vue'
import PromoterSearchDialog from './PromoterSearchDialog.vue'

export default {
    name: 'PromoterDrawer',
    components: {
        infoDialog: PromoterInfoDialog,
        searchDialog: PromoterSearchDialog,
    },
    props:{
        selectedShifts: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    data() {
        return {
            loading: true,
            drawer: true,
            selectedIndex: -1,
            filters: {
                campaign: this.$route.params.id,
                work_date: '',
                name: ''
            },
            permissions: {
                can_deploy: false
            },
            items: [],
            areas: [],
        }
    },
    computed: {
        searchDialog() {
            return this.$refs.searchDialog
        },
        infoDialog() {
            return this.$refs.infoDialog
        }
    },
    methods: {
        get: function() {
            this.loading = true
            this.items = []

            this.$store.dispatch('epanel/shift/getShiftPromoters', this.filters).then((response) => {
                this.areas = this.areas.length === 0?response.data.areas:this.areas
                this.items = response.data.items
                this.permissions = response.data.permissions
                this.loading = false
            }).catch(() => {
                this.loading = false
            })
        },
        search: function() {
            this.get()
        },
        reset: function() {
            this.filters.name = ''
            this.filter.area = ''
        },
        updateObject: function(item) {
            this.filters.work_date = item.work_date
            this.get()
        },
        openInfoDialog: function(item, index) {
            this.selectedIndex = index
            this.infoDialog.updateObject({ id: item.id })
            this.infoDialog.open()
        },
        openDeployDialog: function(item) {
            this.$emit("deploy", { account: item.id })
        },
        openSearchDialog: function() {
            this.searchDialog.open()
        },
        getStatusColor: function(isFree) {
            return isFree?'green--text lighten-4':'red--text lighten-4'
        },
        updateFilters: function(filters) {
            this.filters.name = filters.name
            this.filters.area = filters.area
            this.get()
        },
        isDeployable: function(item) {
            if(!item.is_free){
                return false
            }

            return this.selectedShifts.filter(item => item.status.value === 'D').length === 1
        }
    }
}

</script>


<style scoped>
.list /deep/ .v-list-item, list-item {
    min-height: 48px !important;
    max-height: 48px !important;
}

/deep/ .action-list {
    padding: 0px !important;
}

/deep/ .action-list .v-list-item {
    min-height: 36px !important;
    max-height: 36px !important;
}
</style>