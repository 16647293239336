<template>
	<div>
        <template v-if="permissions.can_list">
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-sheet color="white" elevation="0" class="pa-0">
                        <p class="text-h6 mb-4">Search Shifts</p>
                        <v-row>
                            <v-col cols="12" xs="12" sm="12" md="9" lg="9" xl="9">
                                <v-dialog persistent width="320px" ref="workDateDialog" :return-value.sync="filters.work_dates" v-model="workDateDialog">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field readonly dense hide-details label="Work Date" v-on="on" v-bind="attrs" v-model="filters.work_dates"></v-text-field>
                                    </template>
                                    <v-date-picker scrollable multiple show-current color="primary" :events="object.work_dates" :min="object.min_date" :max="object.max_date" v-model="filters.work_dates">
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" class="elevation-0" :ripple="false" @click="workDateDialog=false">Cancel</v-btn>
                                        <v-btn text color="primary" class="elevation-0" :ripple="false" @click="$refs.workDateDialog.save(filters.work_dates)">OK</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3" order="last">
                                <v-btn small color="primary" class="mr-2 elevation-0" :ripple="false" @click='search'>
                                    <v-icon small left>mdi-magnify</v-icon> Search
                                </v-btn>
                                <v-btn small class="elevation-0" :ripple="false" @click='reset'>
                                    <v-icon small left>mdi-undo</v-icon> Reset
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-sheet>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-btn small color="primary" class="elevation-0" :ripple="false" @click="openCreateDialog" v-if="permissions.can_add">Create Shifts</v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-tabs dense v-model="tab" v-if="work_dates.length > 0">
                        <v-tab :key="index" v-for="(item, index) in work_dates" v-text="item.text" @click="selectWorkDate(item.value)"></v-tab>
                    </v-tabs>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-sheet color="white" elevation="0" class="pa-0">
                        <v-row>
                            <v-col cols="12" xs="6" sm="6" md="2" lg="2" xl="2" class="text-center"># of Empty Shifts</v-col>
                            <v-col cols="12" xs="6" sm="6" md="2" lg="2" xl="2" class="text-center" v-text="object.empty_shifts"></v-col>
                            <v-col cols="12" xs="6" sm="6" md="2" lg="2" xl="2" class="text-center"># of Assigned Shifts</v-col>
                            <v-col cols="12" xs="6" sm="6" md="2" lg="2" xl="2" class="text-center" v-text="object.assigned_shifts"></v-col>
                            <v-col cols="12" xs="6" sm="6" md="2" lg="2" xl="2" class="text-center"># of Shifts</v-col>
                            <v-col cols="12" xs="6" sm="6" md="2" lg="2" xl="2" class="text-center" v-text="object.total_shifts"></v-col>
                        </v-row>
                    </v-sheet>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-btn small color="primary" class="elevation-0 mr-3" :ripple="false" @click="openMassivePublishDialog(1)" v-if="isPublishable()">Publish Shifts</v-btn>
                    <v-btn small color="primary" class="elevation-0 mr-3" :ripple="false" @click="openMassivePublishDialog(2)" v-if="isPublishable()">Unpublish Shifts</v-btn>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="text-right">
                    <v-btn small color="red" class="elevation-0 white--text mr-3" :ripple="false" @click="openRemovePromoterDialog()" v-if="isRemovable()">Remove Promoter</v-btn>
                    <v-btn small color="red" class="elevation-0 white--text mr-3" :ripple="false" @click="openMassiveDeleteDialog()" v-if="isDeletable()">Delete Shifts</v-btn>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-data-table calculate-widths hide-default-header hide-default-footer ref="table" class="elevation-0" loading-text="Loading... Please wait..." checkbox-color="primary" no-data-text="No data available yet" :loading="loading" :items-per-page="300" :headers="headers" :items="items">
                        <template v-slot:header="{ props }">
                            <thead class="v-data-table-header">
                                <tr>
                                    <th class="text-center" width="5%" v-if="!loading">
                                        <v-checkbox :ripple="false" v-model="isSelectedAll" @change="selectItems"></v-checkbox>
                                    </th>
                                    <th class="text-center" :key="index" v-for="(head, index) in props.headers" v-text="head.text"></th>
                                </tr>
                            </thead>
                        </template>
                        <template v-slot:item='{ item, index }'>
                            <tr>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-if="!loading">
                                    <v-checkbox :ripple="false" v-model="item.selected" @change="selectItem(item)"></v-checkbox>
                                </td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.outlet.text"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.start_time"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.end_time"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.break_time"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.total_hours"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.hourly_rate"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center">
                                    <span class="d-block" v-text="item.promoter.name"></span>
                                    <span class="d-block" v-text="item.promoter.mobile_number"></span>
                                </td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center">
                                    <v-chip label small :color="getStatusColor(item.status)"><span v-text="item.status.text"></span></v-chip>
                                </td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center">
                                    <v-chip label small :color="getPromoterStatusColor(item.promoter_status)"><span v-text="item.promoter_status.text" @click="updatePromoterStatus(item.promoter_status.value)"></span></v-chip>
                                </td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center">
                                    <v-menu bottom offset-y v-if="hasMenu(item)">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn small icon v-bind="attrs" v-on="on">
                                                <v-icon>mdi-dots-horizontal</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list dense>
                                            <v-list-item @click="openUpdateDialog(item, index)" v-if="item.can_edit">
                                                <v-list-item-title>Edit Shift</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item @click="openPublishDialog(item, index, 1)" v-if="item.can_publish">
                                                <v-list-item-title>Publish Shift</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item @click="openPublishDialog(item, index, 2)" v-if="item.can_unpublish">
                                                <v-list-item-title>Unpublish Shift</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item @click="openDeleteDialog(item, index)" v-if="item.can_delete">
                                                <v-list-item-title>Delete Shift</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </template>
        <promoter-drawer ref="promoterDrawer" :selectedShifts="selectedItems" @deploy="openDeployDialog"></promoter-drawer>
        <create-dialog :outlets="outlets" :statuses="statuses" :promoters="promoters" :picker-date="object.picker_date" :min-date="object.min_date" :max-date="object.max_date" :shift-dates="object.work_dates" ref="createDialog" @created="updateItems"></create-dialog>
		<update-dialog :outlets="outlets" :statuses="statuses" :promoters="promoters" ref="updateDialog" @updated="updateItem"></update-dialog>
		<delete-dialog ref="deleteDialog" @deleted="deleteItems"></delete-dialog>
		<deploy-dialog is-deploy ref="deployDialog" @deployed="deployItems"></deploy-dialog>
		<deploy-dialog ref="removeDialog" @deployed="removeItems"></deploy-dialog>
		<publish-dialog ref="publishDialog" @updated="updateItemsStatus"></publish-dialog>
		<conflict-dialog ref="conflictDialog"></conflict-dialog>
		<message-notifier ref="notifier"></message-notifier>
	</div>
</template>


<script>

import { copyObject } from '@/utils/helper'
import { stringifyJSON } from '@/utils/encodes'
import MessageNotifier from '../../../shared/MessageNotifier.vue'
import ShiftCreateDialog from './ShiftCreateDialog.vue'
import ShiftUpdateDialog from './ShiftUpdateDialog.vue'
import ShiftDeleteDialog from './ShiftDeleteDialog.vue'
import ShiftDeployDialog from './ShiftDeployDialog.vue'
import ShiftPublishDialog from './ShiftPublishDialog.vue'
import ShiftConflictDialog from './ShiftConflictDialog.vue'
import PromoterDrawer from './promoter/PromoterDrawer.vue'


export default {
	name: 'ShiftList',
    components: {
        messageNotifier: MessageNotifier,
        createDialog: ShiftCreateDialog,
        updateDialog: ShiftUpdateDialog,
        deleteDialog: ShiftDeleteDialog,
        deployDialog: ShiftDeployDialog,
        publishDialog: ShiftPublishDialog,
        conflictDialog: ShiftConflictDialog,
        promoterDrawer: PromoterDrawer,
	},
	data () {
		return {
            tab: null,
			loading: false,
			workDateDialog: false,
			selectedItems: [],
			isSelectedAll: false,
            filters: {
                work_dates: []
            },
            object: {
                picker_date: '',
                min_date: '',
                max_date: '',
                work_dates: []
            },
            permissions: {
                can_list: false,
                can_add: false,
                can_edit: false,
                can_publish: false,
                can_delete: false,
                can_remove: false
            },
            headers: [],
            items: [],
            work_dates: [],
            outlets: [],
            promoters: [],
            statuses: [],
		}
	},
	computed: {
        createDialog() {
            return this.$refs.createDialog
        },
        updateDialog() {
            return this.$refs.updateDialog
        },
        deleteDialog() {
            return this.$refs.deleteDialog
        },
        deployDialog() {
            return this.$refs.deployDialog
        },
        removeDialog() {
            return this.$refs.removeDialog
        },
        publishDialog() {
            return this.$refs.publishDialog
        },
        conflictDialog() {
            return this.$refs.conflictDialog
        },
        promoterDrawer() {
            return this.$refs.promoterDrawer
        },
        notifier() {
            return this.$refs.notifier
        }
	},
    mounted: function() {
        this.get()
    },
	methods: {
        get: function() {
            this.loading = true
            this.items = []
            this.selectedItems = []

            this.$store.dispatch('epanel/shift/getShifts', this.getFilters()).then((response) => {
                if(!this.filters.work_date){
                    this.headers = response.data.headers
                    this.work_dates = response.data.work_dates
                    this.outlets = response.data.outlets
                    this.promoters = response.data.promoters
                    this.statuses = response.data.promoter_status
                }

                this.object = response.data.object
                this.items = response.data.items
                this.permissions = response.data.permissions
                this.getPromoterDrawer()
                this.loading = false
			}).catch(() => {
				this.loading = false
			})
		},
        getPromoterDrawer: function() {
            var object = {}
            if(this.filters.work_date){
                object['work_date'] = this.filters.work_date
            }else{
                object['work_date'] = this.work_dates.length>0?this.work_dates[0].value:''
            }
            this.promoterDrawer.updateObject(object)
        },
		getFilters: function(){
            const workDates = this.filters.work_date?[]:this.filters.work_dates
            return { campaign: this.$route.params.id, work_dates: stringifyJSON(workDates), work_date: this.filters.work_date }
		},
		search: function() {
			this.loading = true
			this.tab = null
			this.filters.work_date = ''
			this.get()
		},
		reset: function() {
			this.filters = { campaign: this.$route.params.id, work_dates: [], work_date: '' }
		},
		selectWorkDate: function(text){
            this.filters.work_date = text
            this.get()
		},
        updateItems: function(item){
            this.openNotifier(item.message)
            if(item.items.length === 0){
                this.get()
                return
            }

            this.conflictDialog.updateItems(item.items)
            this.conflictDialog.open()
        },
        updateItemsStatus: function(item){
            if(this.selectedIndex > -1){
                this.$set(this.items, this.selectedIndex, item.object)
            }else{
                this.get()
                this.isSelectedAll = false
            }
            this.openNotifier(item.message)
        },
        updateItem: function(item) {
            this.$set(this.items, this.selectedIndex, item.object)
            this.openNotifier(item.message)
        },
        deleteItems: function(item) {
            if(this.selectedIndex > -1){
                this.items.splice(this.selectedIndex, 1)
            }else{
                this.get()
                this.isSelectedAll = false
            }
            this.openNotifier(item.message)
        },
        deployItems: function(item) {
            if(this.selectedIndex > -1){
                this.items.splice(this.selectedIndex, 1)
            }else{
                this.get()
                this.isSelectedAll = false
                this.promoterDrawer.get()
            }
            this.openNotifier(item.message)
        },
        removeItems: function(item) {
            this.get()
            this.isSelectedAll = false
            this.openNotifier(item.message)
        },
        selectItems: function(isSelected) {
            this.items.forEach((item) => item.selected = isSelected?true:false)
            this.selectedItems = isSelected?this.items:[]
        },
        selectItem: function() {
            this.selectedItems = this.items.filter(item => item.selected)
            this.isSelectedAll = this.selectedItems.length === this.items.length
        },
        openCreateDialog: function() {
            if(this.permissions.can_add){
                this.createDialog.open()
            }
        },
        openUpdateDialog: function(item, index){
            if(item.can_edit){
                this.selectedIndex = index
                var object = copyObject(item, ['status', 'promoter_status','can_edit', 'can_delete', 'can_publish', 'can_unpublish'])
                object.promoter = item.promoter.value, object.outlet = item.outlet.value
                object.promoter_status = item.promoter_status.value
                object.start_time = item.start_time.substring(0, 5), object.end_time = item.end_time.substring(0, 5)
                this.updateDialog.updateObject(object)
                this.updateDialog.open()
            }
        },
        openDeleteDialog: function(item, index) {
            if(item.can_delete){
                this.selectedIndex = index
                this.deleteDialog.updateObject({ shifts: [item.id] })
                this.deleteDialog.open()
            }
        },
        openMassiveDeleteDialog: function() {
            if(this.isDeletable()){
                var items = []

                this.selectedItems.forEach((item) => {
                    if(item.status.value === 'D'){
                        items.push(item.id)
                    }
                })

                this.selectedIndex = -1
                this.deleteDialog.updateObject({ shifts: items })
                this.deleteDialog.open()
            }
        },
        openPublishDialog: function(item, index, action) {
            if(item.can_publish || item.can_unpublish){
                this.selectedIndex = index
                this.publishDialog.updateObject({ "shifts": [item.id], action: action })
                this.publishDialog.open()
            }
        },
        openMassivePublishDialog: function(action){
            if(!this.isPublishable()){
                return
            }

            var items = []
            this.selectedIndex = -1
            
            this.selectedItems.forEach((item) => {
                if(item.promoter.name){
                    if(action === 1 && item.status.value === 'D'){
                        items.push(item.id)
                    }else if(action === 2 && item.status.value === 'P'){
                        items.push(item.id)
                    }
                }
            })

            this.publishDialog.updateObject({ "shifts": items, action: action })
            this.publishDialog.open()
        },
        openDeployDialog: function(item) {
            if(this.permissions.can_edit){
                this.deployDialog.updateObject({ "shifts": [this.selectedItems[0].id], account: item.account })
                this.deployDialog.open()
            }
        },
        openRemovePromoterDialog: function(){
            if(this.permissions.can_remove){
                var items = []

                this.selectedItems.forEach((item) => {
                    if(item.status.value === 'D' && item.promoter.name){
                        items.push(item.id)
                    }
                })

                this.selectedIndex = -1
                this.removeDialog.updateObject({ shifts: items, account: '' })
                this.removeDialog.open()
            }
        },
        openNotifier: function(message) {
            this.notifier.updateText(message)
            this.notifier.open()
        },
        getStatusColor: function(item) {
            const colors = {"D": "", "P": "light-blue white--text", "BI": "yellow white--text", "BO": "yellow white--text",
                            "CI": "light-green white--text", "CO": "light-green white--text"}
            return colors[item.value]
        },
        getPromoterStatusColor: function(item) {
            const colors = {"KIV": "", "C": "light-blue white--text"}
            return colors[item.value]
        },
        isPublishable: function() {
            return this.permissions.can_publish && this.selectedItems.length > 0
        },
        isRemovable: function() {
            return this.permissions.can_remove && this.selectedItems.length > 0
        },
        isDeletable: function() {
            return this.permissions.can_delete && this.selectedItems.length > 0
        },
        hasMenu: function(item) {
            if(this.selectedItems.length > 0){
                return false
            }

            return item.can_edit || item.can_publish || item.can_unpublish || item.can_delete
        }
	}
}

</script>