<template>
	<v-dialog persistent :max-width="getMaxWidth()" ref="infoDialog" v-model="dialog">
		<v-card>
            <v-card-title>
                <span class="text-h5 mb-3">Promoter Info</span>
            </v-card-title>
            <v-card-text v-if="loading">
                <v-layout align-center justify-center -if="loading">
                    <v-progress-circular indeterminate :width="3" :size="60" color="primary"></v-progress-circular>
                </v-layout>
            </v-card-text>
            <v-card-text v-if="!loading">
                <v-row>
                    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <label class="text-caption text-label font-weight-bold text-label">Profile Picture</label>
                        <v-img contain class="my-3" :max-height="120" :max-width="150" :src="object.image_url" v-if="object.image_url"></v-img>
                        <p v-if="!object.image_url">No profile picture yet</p>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <display-label label="First Name" :text="object.first_name"></display-label>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <display-label label="Last Name" :text="object.last_name"></display-label>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <display-label label="Mobile Number" :text="object.mobile_number"></display-label>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <display-label label="Gender" :text="object.gender"></display-label>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <display-label label="Date of Birth" :text="object.dob"></display-label>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <display-label label="Age" :text="object.age.toString()"></display-label>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <display-label label="Area" :text="object.address.district.area"></display-label>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <display-label label="District" :text="object.address.district.name"></display-label>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <display-label label="Block Number" :text="object.address.block_number"></display-label>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <display-label label="Unit Number" :text="object.address.unit_number?object.address.unit_number:'N.A.'"></display-label>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <display-label label="Building Name" :text="object.address.building_name?object.address.building_name:'N.A.'"></display-label>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                        <display-label label="Postal Code" :text="object.address.postal_code"></display-label>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <display-label label="Street Name" :text="object.address.street_name"></display-label>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <display-label label="No. of Worked Shift" :text="object.performance.shift_quantity?object.performance.shift_quantity:'N.A.'"></display-label>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                        <display-label label="No. of Worked Project" :text="object.performance.project_quantity?object.performance.project_quantity:'N.A.'"></display-label>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                        <display-label label="Average Rating" :text="object.performance.average_rating?object.performance.average_rating:'N.A.'"></display-label>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-center">S/N</th>
                                        <th class="text-center">Project</th>
                                        <th class="text-center">Campaign</th>
                                        <th class="text-center">Outlet</th>
                                        <th class="text-center">Working Date</th>
                                        <th class="text-center">Start Time</th>
                                        <th class="text-center">End Time</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr :key="index" v-for="(item, index) in object.shifts">
                                        <td class="text-center" v-text="index + 1"></td>
                                        <td class="text-center" v-text="item.project"></td>
                                        <td class="text-center" v-text="item.campaign"></td>
                                        <td class="text-center" v-text="item.outlet"></td>
                                        <td class="text-center" v-text="item.work_date"></td>
                                        <td class="text-center" v-text="item.start_time"></td>
                                        <td class="text-center" v-text="item.end_time"></td>
                                    </tr>
                                    <tr class="text-center" v-if="object.shifts.length === 0">
                                        <td colspan="7">No shift yet</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                </v-row>
                <v-row>
                    <div class="comment-section">
                        <label class="labels">Comments on promoter(Latest 3)</label>
                    </div>
                    <v-col cols="12">
                        <div class="comments-separator" v-for="comment in object.remarks.remarks">
                            <span>{{ comment }}</span>
                        </div>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="pl-4 pr-4">
                <v-spacer></v-spacer>
                <v-btn text small @click="close">Close</v-btn>
            </v-card-actions>
        </v-card>
	</v-dialog>
</template>


<script>

import { delay } from '@/utils/helper'
import DisplayLabel from '../../../../shared/DisplayLabel.vue'

export default {
	name: 'ProjectCampaignCreateDialog',
	components: {
        displayLabel: DisplayLabel
	},
	data() {
		return {
			loading: true,
			dialog: false,
			object: {
                id: '',
                first_name: '',
                last_name: '',
                mobile_number: '',
                gender: '',
                dob: '',
                age: '',
                address: {
                    area: '',
                    district: '',
                    block: '',
                    unit_number: '',
                    street_name: '',
                    building_name: '',
                    postal_code: ''
                },
                shifts: [],
                performance: {
                    shift_quantity: 0,
                    project_quantity: 0,
                    average_rating: 0.0
                },
                remarks: []
			}
		}
	},
	computed: {
		infoDialog() {
			return this.$refs.infoDialog
		}
	},
	methods: {
		open: function() {
			this.dialog = true
			if(!this.object.first_name){
                this.loading = true
                delay(this.get, 1000)
            }
		},
		close: function() {
			this.dialog = false
			this.loading = false
			this.object = { id: '', first_name: '', last_name: '', mobile_number: '', gender: '', dob: '', age: '',
                            address: { area: '', district: '', block: '', unit_number: '', street_name: '',
                                       building_name: '', postal_code: '' }, shifts: [], performance: { shift_quantity: '', project_quantity: '', average_rating: '' }, remarks: []}
		},
        updateObject: function(item) {
            this.object.id = item.id
        },
		get: function(){
            this.$store.dispatch('epanel/shift/getShiftPromoter', { id: this.object.id }).then((response) => {
                this.object = response.data.object
                this.loading = false
            }).catch(() => {
                this.loading = false
            })
		},
        getMaxWidth: function() {
            return (this.$vuetify.breakpoint.width * 0.75).toString() + "px"
        }
	}
}

</script>

<style>
    .comment-section {
        padding: 10px 0 0 10px;
    }

    .labels {
        font-size: 0.75rem;
        font-weight: 700;
        letter-spacing: 0.0333333333em;
        font-family: "Roboto", sans-serif;
        padding: 0;
    }

    .comments-separator {
        border-bottom: 0.2px solid black;
        padding: 15px 0;
    }
</style>
